import { Button, Flex, Heading, useDisclosure } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import React, { useMemo } from "react";
import { StatusTag } from "src/components/DataDisplay/StatusTag";
import { EmptyState } from "src/components/EmptyState";
import { Table } from "src/components/Table/Table";
import { FORM_STATUS } from "src/constants";
import { useGlossary } from "src/hooks/useGlossary";
import { SortType } from "src/hooks/useLocalData";
import { useOrganization } from "src/hooks/useOrganization";
import { ReactComponent as ClipboardSvg } from "src/images/clipboard.svg";
import * as GQL from "src/types/graphql";
import { CreateFormDialog } from "../../forms/components/CreateFormDialog";
import { buildFormIdColumnDef } from "./columns/formIdColumn";
import { RelatedFormsColumnIds } from "./helpers";
import { RiAddCircleLine } from "react-icons/ri";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";

interface RelatedFormsTableProps {
  enrollmentPeriodId?: uuid;
  applicantId?: uuid;
  data: GQL.GetFormsByStudentId_form[];
  onSort: (columnName: string, sortType: SortType) => void;
}

type ColumnType = {
  id: string;
  status: GQL.form_status_enum;
  form_template: GQL.GetFormsByStudentId_form_form_template;
};

export const RelatedFormsTable = ({
  enrollmentPeriodId,
  applicantId,
  data,
  onSort,
}: RelatedFormsTableProps) => {
  const { glossary } = useGlossary();
  const organization = useOrganization();

  const columns: ColumnDef<ColumnType>[] = useMemo(
    () => [
      {
        id: RelatedFormsColumnIds.EnrollmentPeriod,
        header: glossary`enrollment period`,
        accessorFn: (row) => row.form_template.enrollment_period.name,
      },
      {
        id: RelatedFormsColumnIds.FormTemplateName,
        header: glossary`form name`,
        accessorFn: (row) => row.form_template.name,
      },
      {
        id: RelatedFormsColumnIds.FormStatus,
        header: glossary`form status`,
        accessorFn: (row) => row.status,
        cell: (props) => (
          <StatusTag status={FORM_STATUS[props.row.original.status]} />
        ),
      },
      buildFormIdColumnDef<ColumnType>({ glossary, organization }),
    ],
    [glossary, organization]
  );

  return (
    <Flex direction="column" gap={2}>
      <Flex justifyContent="space-between">
        <Heading as="h2" fontSize="2xl" fontWeight="400">
          Forms
        </Heading>
        {applicantId && (
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
          >
            <WithUserPermissions permissions={["form:create"]}>
              <CreateFormButton
                enrollmentPeriodId={enrollmentPeriodId}
                applicantId={applicantId}
              />
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
        )}
      </Flex>
      {data.length === 0 ? (
        <EmptyState
          Svg={ClipboardSvg}
          description="This student has no form yet"
          margin="auto"
        />
      ) : (
        <Table
          data={data}
          columns={columns}
          onChangeSort={onSort}
          sortableColumnIds={[
            RelatedFormsColumnIds.EnrollmentPeriod,
            RelatedFormsColumnIds.FormTemplateName,
            RelatedFormsColumnIds.FormId,
            RelatedFormsColumnIds.FormStatus,
          ]}
        />
      )}
    </Flex>
  );
};

interface CreateFormButtonProps {
  enrollmentPeriodId?: uuid;
  applicantId: uuid;
}

function CreateFormButton({
  enrollmentPeriodId,
  applicantId,
}: CreateFormButtonProps) {
  const createFormDisclosure = useDisclosure();

  return (
    <>
      <Button
        aria-label="Create form"
        leftIcon={<RiAddCircleLine />}
        variant="outline"
        colorScheme="gray"
        onClick={() => {
          createFormDisclosure.onOpen();
        }}
      >
        Create form
      </Button>
      <CreateFormDialog
        enrollmentPeriodId={enrollmentPeriodId}
        applicantId={applicantId}
        isOpen={createFormDisclosure.isOpen}
        onClose={createFormDisclosure.onClose}
        applicantSelectInputProps={{ isDisabled: true }}
      />
    </>
  );
}
