import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useOutsideClick,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import { useField } from "formik";
import { FormControl } from "formik-chakra-ui";
import _ from "lodash";
import Markdown from "markdown-to-jsx";
import React from "react";
import { RiCloseLine } from "react-icons/ri";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_STUDENTS } from "src/scenes/orgAdmin/students/graphql/queries";
import * as GQL from "src/types/graphql";
import { getStudentSearchQuery } from "../graphql/utils";
import { Glossary } from "../Text/Glossary";

const PAGE_SIZE = 5;

interface ApplicantSelectInputProp {
  name: string;
  organizationId: uuid;
  placeholder?: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  onSelectedApplicant?: (
    value: GQL.GetStudents_person | null | undefined
  ) => void;
}

export function ApplicantSelectInput({
  name,
  organizationId,
  onSelectedApplicant,
  placeholder = "Search for student by name or ID",
  label = "Student",
  isDisabled = false,
  isRequired = false,
}: ApplicantSelectInputProp) {
  const outsideRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: outsideRef,
    handler: () => closeMenu(),
  });
  const [field, { initialValue }, helper] = useField(name);
  const [hasUserSelected, setHasUserSelected] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [applicants, setApplicants] = React.useState<GQL.GetStudents_person[]>(
    []
  );
  const [totalCount, setTotalCount] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const observerRef = React.useRef<HTMLDivElement | null>(null);

  const [selectedApplicant, setSelectedApplicant] = React.useState<
    GQL.GetStudents_person | null | undefined
  >(undefined);

  const searchValue: string = React.useMemo(
    () =>
      inputValue
        ? inputValue
        : !hasUserSelected && initialValue
        ? initialValue
        : "",
    [initialValue, inputValue, hasUserSelected]
  );

  const toFullName = (
    applicant: GQL.GetStudents_person | null | undefined,
    defaultValue: string = ""
  ) =>
    applicant ? `${applicant.first_name} ${applicant.last_name}` : defaultValue;

  const { remoteData } = useRemoteDataQuery<
    GQL.GetStudents,
    GQL.GetStudentsVariables
  >(GET_STUDENTS, {
    variables: {
      organizationId: organizationId,
      search: {
        _and: [
          getStudentSearchQuery(searchValue || ""),
          { deleted_at: { _is_null: true } },
          { active: { _eq: true } },
        ],
      },
      limit: PAGE_SIZE,
      offset: offset,
      order_by: [
        { first_name: GQL.order_by.asc },
        { last_name: GQL.order_by.asc },
        { birth_date: GQL.order_by.asc },
      ],
    },
    skip: !searchValue || searchValue.length < 3,
    fetchPolicy: "network-only",
  });

  const fetchMoreStudents = () => {
    if (applicants.length < totalCount) {
      setOffset(applicants.length);
    }
  };

  React.useEffect(() => {
    if (remoteData.hasError()) {
      helper.setError("Error finding students");
      setSelectedApplicant(null);
      onSelectedApplicant?.(null);
      setApplicants([]);
    }
  }, [remoteData, helper, onSelectedApplicant]);

  React.useEffect(() => {
    if (
      !remoteData.isLoading() &&
      !remoteData.hasError() &&
      remoteData.hasData()
    ) {
      if (offset === 0) {
        setApplicants(remoteData.data.person);
      } else {
        setApplicants((prev) => _.unionBy(prev, remoteData.data.person, "id"));
      }
      setTotalCount(remoteData.data.person_aggregate.totals?.total ?? 0);
    }
  }, [remoteData, offset]);

  React.useEffect(() => {
    if (!hasUserSelected && applicants.length > 0) {
      const preSelectedApplicant = applicants.find(
        (applicant) => applicant.id === initialValue
      );
      if (preSelectedApplicant) {
        setSelectedApplicant(preSelectedApplicant);
        onSelectedApplicant?.(preSelectedApplicant);
        setInputValue(toFullName(preSelectedApplicant));
        setOffset(0);
      }
    }
  }, [applicants, hasUserSelected, initialValue, onSelectedApplicant]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0]?.isIntersecting &&
          !remoteData.isLoading() &&
          applicants.length > 0
        ) {
          fetchMoreStudents();
        }
      },
      { rootMargin: "20px" } // Trigger loading slightly before reaching the bottom
    );

    const current = observerRef.current;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicants]);

  const handleChange = (value: string | null) => {
    value = value ?? "";
    if (value === field.value) return;

    setHasUserSelected(true);
    const found =
      applicants.find((applicant) => applicant.id === value) || null;

    setSelectedApplicant(found);
    onSelectedApplicant?.(found);
    helper.setTouched(true, false); // no validate until setValue()
    helper.setValue(value);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getLabelProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox<GQL.GetStudents_person>({
    items: applicants,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    itemToString: toFullName,
    onInputValueChange({ inputValue: newValue }) {
      if (newValue !== inputValue) {
        setInputValue(newValue ?? "");
        setOffset(0);
      }
    },
    stateReducer(
      state,
      actionAndChanges
    ): Partial<typeof actionAndChanges.changes> {
      const { changes, type, index } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          const newIndex =
            index !== undefined && index >= 0
              ? index
              : changes.highlightedIndex !== undefined &&
                changes.highlightedIndex >= 0
              ? changes.highlightedIndex
              : undefined;
          const newSelectedItem =
            newIndex !== undefined ? applicants[newIndex] : null;

          return {
            ...changes,
            selectedItem: newSelectedItem,
          };
        }
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem: {
          return {
            ...changes,
            inputValue: toFullName(selectedApplicant, changes.inputValue),
          };
        }
        case useCombobox.stateChangeTypes.InputBlur: {
          return changes;
        }
        default:
          return changes;
      }
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        handleChange(selectedItem.id);
      }
    },
  });

  const openMenuUnlessDisabled = React.useCallback(() => {
    if (!isDisabled) {
      openMenu();
    }
  }, [isDisabled, openMenu]);

  const markdownName = React.useCallback(
    (name: string) => {
      if (!inputValue) return name;

      const escapedInput = inputValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(`(${escapedInput})`, "ig");
      return name.replace(regex, "**$1**");
    },
    [inputValue]
  );

  const renderDropdownItem = React.useCallback(
    (applicant: GQL.GetStudents_person) => {
      const name = markdownName(toFullName(applicant));
      return (
        <Flex direction="column" gap="0" width="100%">
          <Flex direction="row" gap="0" width="100%">
            <Text fontSize="md" marginEnd="auto">
              <Markdown>{name}</Markdown>
            </Text>
            {applicant.birth_date && (
              <Text fontSize="sm">Born {applicant.birth_date}</Text>
            )}
          </Flex>
          <Text fontSize="sm" overflow="visible">
            ID: {applicant.id}
          </Text>
          {applicant.reference_id && (
            <Text fontSize="sm">Reference ID: {applicant.reference_id}</Text>
          )}
        </Flex>
      );
    },
    [markdownName]
  );

  return (
    <Box position="relative" width="100%" ref={outsideRef}>
      <FormControl name={name} isRequired={isRequired}>
        <FormLabel {...getLabelProps()} htmlFor={name}>
          <Glossary>{label}</Glossary>
        </FormLabel>
        {selectedApplicant ? (
          <Flex
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            paddingLeft={4}
            paddingRight={3}
            paddingTop={2}
            paddingBottom={2}
            gap={2.5}
            direction="row"
          >
            {renderDropdownItem(selectedApplicant)}
            {!isDisabled && (
              <Button
                variant="outline"
                colorScheme="gray"
                size="sm"
                p="0"
                onClick={() => {
                  handleChange(null);
                }}
              >
                <RiCloseLine />
              </Button>
            )}
          </Flex>
        ) : (
          <>
            <InputGroup
              onClick={openMenuUnlessDisabled}
              onFocus={openMenuUnlessDisabled}
              display="flex"
              justifyContent="center"
            >
              <InputLeftElement children={<SearchIcon color="gray.500" />} />
              <Input
                {...getInputProps(
                  {
                    onBlur: (event) => {
                      helper.setTouched(true);
                    },
                  },
                  { suppressRefError: true }
                )}
                value={inputValue}
                placeholder={placeholder}
                isDisabled={isDisabled}
              />
            </InputGroup>
            <UnorderedList
              {...getMenuProps({}, { suppressRefError: true })}
              display={isOpen ? "block" : "none"}
              position="absolute"
              background="white"
              marginLeft={0}
              zIndex="2000"
              boxShadow="lg"
              width="100%"
              marginTop={1}
              overflow="auto"
              maxHeight="300px"
            >
              {applicants.map((applicant, index) => (
                <ListItem
                  {...getItemProps({ item: applicant, index })}
                  key={applicant.id}
                  backgroundColor={
                    highlightedIndex === index ? "gray" : "white"
                  }
                  listStyleType="none"
                >
                  <Flex align="center" minHeight="16" padding="4">
                    {renderDropdownItem(applicant)}
                  </Flex>
                </ListItem>
              ))}
              {remoteData.isLoading() && (
                <ListItem textAlign="center" p={2}>
                  <Spinner />
                </ListItem>
              )}
              <Box ref={observerRef} />
            </UnorderedList>
          </>
        )}
      </FormControl>
    </Box>
  );
}
