import { PopoverContentProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { getPreviewUrl, type Logo } from "./schema";
import { FileItem } from "./FileItem";

type Props = {
  logo: Logo;
  onDelete: () => void;
  previewPopoverProps?: PopoverContentProps;
};
export const LogoPreview: FunctionComponent<Props> = ({
  logo,
  onDelete,
  previewPopoverProps,
}) => {
  return (
    <FileItem
      name={logo.name}
      onDelete={onDelete}
      previewUrl={getPreviewUrl(logo)}
      previewPopoverProps={previewPopoverProps}
    />
  );
};
