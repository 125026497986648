import { Env } from "src/services/env";
import { z } from "zod";

const NewLogoSchema = z.object({
  type: z.literal("new"),
  objectUrl: z.string(),
  imageType: z.enum(["png", "svg"]),
  name: z.string(),
});
const ExistingLogoSchema = z.object({
  type: z.literal("existing"),
  imageType: z.enum(["png", "svg"]),
  name: z.string(),
  url: z.string(),
});
export const LogoSchema = z.discriminatedUnion("type", [
  NewLogoSchema,
  ExistingLogoSchema,
]);
export type NewLogo = z.infer<typeof NewLogoSchema>;
export type ExistingLogo = z.infer<typeof ExistingLogoSchema>;
export type Logo = z.infer<typeof LogoSchema>;

/**
 * Retrieves the URL for previewing a logo image.
 * For existing logos, returns their stored URL.
 * For new logos, returns the temporary object URL.
 * @param logo - The logo object to get preview URL for
 * @returns The preview URL string
 */
export function getPreviewUrl(logo: Logo): string {
  return logo.type === "existing" ? logo.url : logo.objectUrl;
}

/**
 * Converts a NewLogo object into a File object by fetching its contents.
 * Also cleans up the object URL after fetching to prevent memory leaks.
 * @param logo - The NewLogo object containing the object URL and filename
 * @returns Promise resolving to a File object containing the logo data
 */
export async function getFile(logo: NewLogo): Promise<File> {
  const response = await fetch(logo.objectUrl);
  const blob = await response.blob();
  const file = new File([blob], logo.name, {
    type: blob.type,
  });

  URL.revokeObjectURL(logo.objectUrl);
  return file;
}

/**
 * Constructs the full URL for accessing a logo image.
 * Combines the environment's base URL with the organization-specific logo path.
 * @param env - Environment configuration object containing base URL
 * @param orgPath - Organization path string
 * @param logo - Optional Logo object containing the logo details
 * @returns Full URL string for accessing the logo, or empty string if no logo provided
 */
export function toUrl(
  env: Env,
  orgPath: string,
  logo: Logo | undefined
): string {
  if (logo === undefined) {
    return "";
  }
  return `${env.REACT_APP_CONFIG_URL}/${toPath(orgPath, logo)}`;
}

/**
 * Constructs the relative path for a logo within the organization structure.
 * Combines the organization path with the logo filename to create the storage path.
 * @param orgPath - Organization path identifier
 * @param logo - Logo object containing the filename
 * @returns Path string in format 'organization/v1/{orgPath}/logos/{filename}'
 */
export function toPath(orgPath: string, logo: Logo): string {
  return `organization/v1/${orgPath}/logos/${logo.name}`;
}
