import { IconType } from "react-icons";
import {
  RiCheckFill,
  RiCheckLine,
  RiCloseCircleLine,
  RiCloseLine,
  RiDeleteBinLine,
  RiEditLine,
  RiLogoutBoxLine,
  RiLogoutBoxRLine,
  RiRefreshLine,
  RiTimeLine,
} from "react-icons/ri";
import { StatusTagMetadata } from "../components/DataDisplay/StatusTag";
import * as GQL from "../types/graphql";

/**
 * The maximum number of simultaneous chunked queries to run.  This matches
 * Apollo's default batch size of 10.
 */
export const CHUNKED_BATCH_SIZE = 10;

export const STATUS_ACTIVE = "Active";
export const STATUS_CLOSED = "Closed";
export const STATUS_INACTIVE = "Inactive";

export const ELEVATED_SCHOOL_ADMIN_GROUP_NAME = "elevated_school_admin";

export type MultiSelectOptionType = {
  id?: string;
  value: string;
  label: string;
};

export const PAGINATION_DEFAULT_LIMIT = 25;
export const PAGINATION_DEFAULT_OFFSET = 0;

interface FormStatusTagMetadata extends StatusTagMetadata {
  menuItemDescription?: string;
  menuItemSelectedDescription?: string;
  statusDialogHeader?: string;
  statusDialogDescription?: string;
}

export type FormStatusType = {
  label: string;
  longLabel: string;
  icon?: IconType;
  variant?: string;
  colorScheme: string;
  accentColor?: string;
  menuItemDescription?: string;
  menuItemSelectedDescription?: string;
  statusDialogHeader?: string;
  statusDialogDescription?: string;
  isActive: boolean;
};

type FormStatusTypes = Record<GQL.form_status_enum, FormStatusType>;

type PermissionLevelsTypes = Record<
  GQL.person_type_enum,
  FormStatusTagMetadata
>;

export const FORM_STATUS: FormStatusTypes = {
  [GQL.form_status_enum.InProgress]: {
    label: "In progress",
    longLabel: "Form response in progress",
    icon: RiEditLine,
    colorScheme: "orange",
    menuItemDescription: "Form response in progress and not submitted",
    menuItemSelectedDescription: "Currently in progress",
    statusDialogHeader: "Revert form response?",
    statusDialogDescription:
      "Reverting the form response to “In progress” will allow it to be edited and submitted.",
    isActive: true,
  },
  [GQL.form_status_enum.Submitted]: {
    label: "Submitted",
    longLabel: "Form response submitted",
    icon: RiCheckFill,
    colorScheme: "green",
    menuItemDescription: "Submit this form response",
    menuItemSelectedDescription: "Currently submitted",
    statusDialogHeader: "Submit form response?",
    statusDialogDescription:
      "Submitting the form response will enter it into the lottery process.",
    isActive: true,
  },
  [GQL.form_status_enum.Cancelled]: {
    label: "Canceled",
    longLabel: "Form response canceled",
    icon: RiCloseCircleLine,
    colorScheme: "gray",
    menuItemDescription: "Withdraw form response from lottery",
    menuItemSelectedDescription: "Currently withdrawn from lottery",
    statusDialogHeader: "Cancel form response?",
    statusDialogDescription:
      "Canceling the form response will withdraw it from the lottery process.",
    isActive: true,
  },
  [GQL.form_status_enum.Deleted]: {
    // This is actually won't be used, we just need this to satisfy the type system.
    label: "Deleted",
    longLabel: "Form response deleted",
    icon: RiDeleteBinLine,
    colorScheme: "red",
    isActive: false,
  },
  [GQL.form_status_enum.Withdrawn]: {
    label: "Withdrawn",
    longLabel: "Form response withdrawn",
    icon: RiLogoutBoxLine,
    colorScheme: "gray",
    menuItemDescription: "Parent/guardian only",
    menuItemSelectedDescription: "Currently withdrawn by the parent/guardian",
    isActive: false,
  },
  [GQL.form_status_enum.Verified]: {
    label: "Verified",
    longLabel: "Form response verified",
    icon: RiCheckFill,
    colorScheme: "blue",
    accentColor: "#3182CE",
    menuItemDescription: "Verify and prevent changes from parents",
    menuItemSelectedDescription:
      "Fully verified, parents can't edit form response",
    statusDialogHeader: "Verify form response?",
    statusDialogDescription:
      "Verifying the form response indicates that it has been reviewed and approved. It will also partially prevent it from being edited by the parent/guardian.",
    isActive: true,
  },
  [GQL.form_status_enum.LotteryReady]: {
    label: "Ready for lottery",
    longLabel: "Form response ready for lottery",
    icon: RiCheckFill,
    colorScheme: "purple",
    accentColor: "#805AD5",
    menuItemDescription: "Approve for lottery and prevent changes from parents",
    menuItemSelectedDescription:
      "Approved for lottery, parents can't edit the form response",
    statusDialogHeader: "Set form response as ready for lottery?",
    statusDialogDescription:
      "Setting the form response as ready for lottery will approve it for the lottery process. It will also prevent it from being edited by the parent/guardian.",
    isActive: true,
  },
  [GQL.form_status_enum.Admissions]: {
    label: "Admissions",
    longLabel: "Admissions",
    icon: RiLogoutBoxRLine,
    colorScheme: "blue",
    accentColor: "#63B3ED",
    menuItemDescription: "Form response in lottery and offers",
    menuItemSelectedDescription: "Currently in admissions",
    statusDialogHeader: "Set form response as admission?",
    statusDialogDescription: "Please update me here.",
    isActive: true,
  },
};

// for internal categorization
export enum FilterTypes {
  Status = "status",
  SubmittedBefore = "submittedBefore",
  School = "school",
  AttendingSchool = "attendingSchool",
  Rank = "rank",
  Tags = "tags",
  VerificationStatus = "verificationStatus",
  DynamicQuestions = "dynamicQuestions",
  Grades = "grades",
  Badges = "badges",
  SubStatus = "subStatus",
  WithinCapacity = "withinCapacity",
  PreferredLanguage = "preferredLanguage",
  Visibility = "visibility",
}

export enum AttendanceTypes {
  Attending = "attending",
  Applying = "applying",
}

// for inserting parameters into URL string
export enum SearchAndFilterTypes {
  Search = "search",
  EnrollmentPeriod = "enrollmentPeriod",
  Limit = "limit",
  Offset = "offset",
  SortKey = "sortKey",
  SortType = "sortType",
  // List of ids to message communication
  Recipients = "recipients",
  RelatedTo = "relatedTo",
  Forms = "forms",
  Badges = "badges",
  // Tab type on the admission list view
  Tab = "tab",
  DropoffFormTemplateId = "dropoffFormTemplateId",
  // Remaining are the filters on the admission list view.
  // Make sure to also add these to the FILTER_TYPES constant
  Status = "status",
  SubmittedBefore = "submittedBefore",
  School = "school",
  AttendingSchool = "attendingSchool",
  Rank = "rank",
  Tag = "tag",
  VerificationStatus = "verificationStatus",
  VerificationIds = "verificationIds",
  DynamicQuestions = "dynamicQuestions",
  Grades = "grades",
  SubStatus = "subStatus",
  WithinCapacity = "withinCapacity",
  PreferredLanguage = "preferredLanguage",
  Visibility = "visibility",
  Attendance = "attendance",
}

// for persisting form state on the client
export enum ClientStorageTypes {
  EnrollmentPeriod = "enrollmentPeriod",
  FormTemplate = "formTemplate",
}

export const FILTER_TYPES = [
  SearchAndFilterTypes.Status,
  SearchAndFilterTypes.SubmittedBefore,
  SearchAndFilterTypes.School,
  SearchAndFilterTypes.VerificationStatus,
  SearchAndFilterTypes.VerificationIds,
  SearchAndFilterTypes.Grades,
  SearchAndFilterTypes.DynamicQuestions,
  SearchAndFilterTypes.SubStatus,
  SearchAndFilterTypes.Rank,
  SearchAndFilterTypes.Tag,
  SearchAndFilterTypes.WithinCapacity,
  SearchAndFilterTypes.PreferredLanguage,
  SearchAndFilterTypes.Attendance,
];

export const MESSAGE_CONTENT_LIMIT = 320;
export const TAG_NAME_LIMIT = 25;

export const PERMISSION_LEVELS: PermissionLevelsTypes = {
  [GQL.person_type_enum.admin]: {
    label: "INTERNAL ONLY",
    longLabel: "INTERNAL ONLY",
    variant: "solid",
    colorScheme: "gray",
  },
  // We are not using right now, but we need to satisfy the type system.
  [GQL.person_type_enum.applicant]: {
    label: "Internal Only",
    longLabel: "Internal only",
    colorScheme: "gray",
  },
  [GQL.person_type_enum.guardian]: {
    label: "Internal Only",
    longLabel: "Internal only",
    colorScheme: "gray",
  },
  [GQL.person_type_enum.orgAdmin]: {
    label: "Internal Only",
    longLabel: "Internal only",
    colorScheme: "gray",
  },
  [GQL.person_type_enum.districtAdmin]: {
    label: "Internal Only",
    longLabel: "Internal only",
    colorScheme: "gray",
  },
  [GQL.person_type_enum.schoolAdmin]: {
    label: "Internal Only",
    longLabel: "Internal only",
    colorScheme: "gray",
  },
};

export const MAX_FILE_SIZE = 5000000;
export const MAX_NUMBER_OF_FILES = 5;

export const UPLOAD_FILE_STATE = {
  error: {
    colorScheme: "red",
    icon: RiCloseLine,
    messages: {
      FILE_TOO_LARGE: "File is larger than 5MB",
      NETWORK_ERROR: "Network error, check your connection",
      UPLOAD_ERROR: "Upload failed",
      UPLOAD_ERROR_DEFAULT: "Upload failed, try again",
    },
  },
  success: {
    colorScheme: "green",
    icon: RiCheckLine,
    messages: {
      UPLOAD_SUCCESS: "Upload complete!",
    },
  },
  loading: {
    colorScheme: "blue",
    icon: RiRefreshLine,
    messages: {
      UPLOADING: "Uploading",
    },
  },
};

export const GENERATE_DOWNLOAD_URL = "generate-download-url";
export const GENERATE_UPLOAD_URL = "generate-upload-url";

export const FORM_STATUS_DESCRIPTION_MAX_CHARS = 210;
export const FORM_DESCRIPTION_MAX_CHARS = 50;

export const OFFER_STATUS: Record<GQL.offer_status_enum, FormStatusType> = {
  [GQL.offer_status_enum.Offered]: {
    label: "Offered",
    longLabel: "Offer has been made",
    icon: RiLogoutBoxRLine,
    colorScheme: "blue",
    isActive: true,
  },
  [GQL.offer_status_enum.Accepted]: {
    label: "Accepted",
    longLabel: "Offer has been accepted",
    icon: RiCheckLine,
    colorScheme: "green",
    isActive: true,
  },
  [GQL.offer_status_enum.Declined]: {
    label: "Declined",
    longLabel: "Offer has been declined",
    icon: RiCloseCircleLine,
    colorScheme: "gray",
    isActive: true,
  },
  [GQL.offer_status_enum.Revoked]: {
    label: "Revoked",
    longLabel: "Offer has been revoked",
    icon: RiCloseCircleLine,
    colorScheme: "gray",
    isActive: true,
  },
};

export const WAITLIST_STATUS: Record<GQL.waitlist_status_enum, FormStatusType> =
  {
    [GQL.waitlist_status_enum.Waitlisted]: {
      label: "Waitlisted",
      longLabel: "Form has been waitlisted",
      icon: RiTimeLine,
      colorScheme: "orange",
      isActive: true,
    },
    [GQL.waitlist_status_enum.Removed]: {
      label: "Removed",
      longLabel: "Form has been removed from waitlist",
      icon: RiCloseCircleLine,
      colorScheme: "gray",
      isActive: true,
    },
    [GQL.waitlist_status_enum.Withdrawn]: {
      label: "Withdrawn",
      longLabel: "Form has been withdrawn from waitlist",
      icon: RiCloseCircleLine,
      colorScheme: "gray",
      isActive: true,
    },
  };

export type FormSchoolRankSubStatusIndex =
  | GQL.offer_status_enum
  | GQL.waitlist_status_enum
  | GQL.form_school_rank_status_enum;

export const FORM_SCHOOL_RANK_SUB_STATUS: Record<
  FormSchoolRankSubStatusIndex,
  FormStatusType
> = {
  ...OFFER_STATUS,
  ...WAITLIST_STATUS,
  [GQL.form_school_rank_status_enum.NotConsidered]: {
    label: "Not considered",
    longLabel: "Form not considered for the lottery",
    variant: "outline",
    colorScheme: "gray",
    isActive: true,
  },
};

export enum FormTabsTypes {
  Submissions = "submissions",
  Waitlists = "waitlists",
  Offers = "offers",
  All = "all",
  FormListImports = "form-list-imports",
  DropoffForms = "dropoff-forms",
}

export type Tab = FormTab | DropoffFormTab;
export type FormTab = {
  readonly type: Exclude<FormTabsTypes, FormTabsTypes.DropoffForms>;
  readonly key: string;
  readonly label: string;
  isVisible: boolean;
};

export type DropoffFormTab = {
  readonly type: FormTabsTypes.DropoffForms;
  readonly key: string;
  readonly label: string;
  readonly formTemplateId: uuid;
  readonly isVisible: boolean;
};

export const ADMISSION_TABS: [FormTab, ...FormTab[]] = [
  {
    type: FormTabsTypes.All,
    label: "All",
    key: FormTabsTypes.All,
    isVisible: true,
  },
  {
    type: FormTabsTypes.Submissions,
    label: "Submissions",
    key: FormTabsTypes.Submissions,
    isVisible: true,
  },
  {
    type: FormTabsTypes.Waitlists,
    label: "Waitlists",
    key: FormTabsTypes.Waitlists,
    isVisible: true,
  },
  {
    type: FormTabsTypes.Offers,
    label: "Offers",
    key: FormTabsTypes.Offers,
    isVisible: true,
  },
  {
    type: FormTabsTypes.FormListImports,
    label: "Imports",
    key: FormTabsTypes.FormListImports,
    isVisible: false,
  },
];

export function getAdmissionTab(key: FormTabsTypes) {
  return ADMISSION_TABS.find((tab) => tab.key === key);
}

export function getAdmissionTabByString(keyString: string) {
  return getAdmissionTab(keyString as FormTabsTypes);
}

export const GENERIC_ERROR = {
  title: "Oops! Something went wrong!",
  description: "Check your content or network and try again later",
};

export enum VisibilityOptions {
  Visible = "visible",
  NotVisible = "notVisible",
}

export const VISIBILITY_FILTER_OPTIONS = [
  {
    label: "Visible",
    key: VisibilityOptions.Visible,
  },
  {
    label: "Not visible",
    key: VisibilityOptions.NotVisible,
  },
];

export enum SubmittedBeforeOptions {
  SubmittedBefore = "submittedBefore",
  NotSubmittedBefore = "notSubmittedBefore",
}

export const SUBMITTED_BEFORE_FILTER_OPTIONS = [
  {
    label: "Submitted before",
    key: SubmittedBeforeOptions.SubmittedBefore,
  },
  {
    label: "Not submitted before",
    key: SubmittedBeforeOptions.NotSubmittedBefore,
  },
];

interface MessageTemplate {
  label: string;
}

type MessageTemplates = Record<GQL.message_template_type_enum, MessageTemplate>;

export const MESSAGE_TEMPLATES: MessageTemplates = {
  // deprecated
  [GQL.message_template_type_enum.FormAdminCancelled]: {
    label: "Form admin canceled",
  },
  [GQL.message_template_type_enum.FormCancelled]: {
    label: "Form canceled",
  },
  [GQL.message_template_type_enum.FormDeleted]: {
    label: "Form deleted",
  },
  [GQL.message_template_type_enum.FormStarted]: {
    label: "Form started",
  },
  [GQL.message_template_type_enum.FormSubmitted]: {
    label: "Form submitted",
  },
  [GQL.message_template_type_enum.OfferAccepted]: {
    label: "Offer accepted",
  },
  [GQL.message_template_type_enum.OfferDeclined]: {
    label: "Offer declined",
  },
  [GQL.message_template_type_enum.OfferExtended]: {
    label: "Offer extended",
  },
  [GQL.message_template_type_enum.OfferRevoked]: {
    label: "Offer revoked",
  },
  [GQL.message_template_type_enum.Waitlisted]: {
    label: "Waitlisted",
  },
  [GQL.message_template_type_enum.WaitlistWithdrawn]: {
    label: "Waitlist withdrawn",
  },
  [GQL.message_template_type_enum.WaitlistRemoved]: {
    label: "Waitlist removed",
  },
};

interface AnnouncementType {
  label: string;
  value: GQL.announcement_type_enum;
}

type AnnouncementTypes = Record<GQL.announcement_type_enum, AnnouncementType>;

export const ANNOUNCEMENT_TYPES: AnnouncementTypes = {
  [GQL.announcement_type_enum.INFO]: {
    label: "Informational",
    value: GQL.announcement_type_enum.INFO,
  },
};

interface AnnouncementEntryPoint {
  label: string;
  value: GQL.announcement_entry_point_enum;
}

type AnnouncementEntryPoints = Record<
  GQL.announcement_entry_point_enum,
  AnnouncementEntryPoint
>;

export const ANNOUNCEMENT_ENTRY_POINTS: AnnouncementEntryPoints = {
  [GQL.announcement_entry_point_enum.ADMIN_PORTAL]: {
    label: "Admin portal",
    value: GQL.announcement_entry_point_enum.ADMIN_PORTAL,
  },
  [GQL.announcement_entry_point_enum.PARENT_PORTAL]: {
    label: "Family portal",
    value: GQL.announcement_entry_point_enum.PARENT_PORTAL,
  },
};
