import { useRemoteDataQuery } from "./useRemoteDataQuery";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { useMemo } from "react";
import { useGetAnswers } from "src/generated/hooks/form-service/queries";
import { GetAnswersResponse } from "src/generated/hooks/form-service/requests";

// Answer IDs are not used for updating answers, but are required in the GQL type
// Pass this known value forward to satisfy the type
const KNOWN_ANSWER_ID = "f47ac10b-58cc-4372-a567-0e02b2c3d479";

export function useFormAnswers(
  formTemplate: GQL.GetFormViewById_form_by_pk_form_template | undefined,
  formId: string | undefined,
  skip: boolean = false
): RD.RemoteData<Error, GQL.GetFormAnswersById> {
  const flags = useFlags(["form-service"]);
  const isFormServiceEnabled = flags["form-service"].enabled;

  const { remoteData: graphqlRemoteData } = useRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, {
    variables: {
      form_id: formId ?? "",
    },
    skip: skip || !formId || isFormServiceEnabled,
  });

  const {
    data: formServiceData,
    error: formServiceError,
    isLoading: formServiceIsLoading,
  } = useGetAnswers(
    {
      path: {
        formId: formId ?? "",
      },
    },
    [formId],
    {
      enabled: !skip && !!formId && isFormServiceEnabled,
    }
  );

  const formServiceResult: RD.RemoteData<Error, GQL.GetFormAnswersById> =
    useMemo(() => {
      if (!isFormServiceEnabled) {
        return RD.notAsked();
      }
      if (formServiceIsLoading || !formTemplate) {
        return RD.loading();
      }
      if (formServiceError || !formServiceData) {
        return RD.failure(
          new Error(
            formServiceError?.error?.message ?? "Error fetching form answers"
          )
        );
      }
      return RD.success(convertFormServiceToGQL(formTemplate, formServiceData));
    }, [
      isFormServiceEnabled,
      formServiceIsLoading,
      formServiceError,
      formServiceData,
      formTemplate,
    ]);

  if (isFormServiceEnabled) {
    return formServiceResult;
  }
  return graphqlRemoteData;
}

function convertFormServiceToGQL(
  formTemplate: GQL.GetFormViewById_form_by_pk_form_template,
  formServiceData: GetAnswersResponse
): GQL.GetFormAnswersById {
  const result: GQL.GetFormAnswersById = {
    form_answer: [],
    grades_answer: [],
    form_address: [],
    custom_question_answer: [],
    form_disclaimer: [],
  };

  // Helper function to create base form answer
  const createBaseFormAnswer = (
    questionId: string,
    question: { type: GQL.question_type_enum }
  ) => ({
    __typename: "form_answer" as const,
    id: KNOWN_ANSWER_ID,
    question_id: questionId,
    form_question: {
      __typename: "form_question" as const,
      question_id: questionId,
      category: "" as any,
      question: {
        __typename: "question" as const,
        type: question.type,
      },
    },
    free_text_answer: null,
    number_answer: null,
    date_answer: null,
    form_answer_options: [],
    document_metadata: [],
  });

  // Helper function to handle standard form answers
  const handleStandardFormAnswer = (
    questionId: string,
    answer: unknown,
    question: {
      type: GQL.question_type_enum;
      form_question?: { category: GQL.form_question_category_enum } | null;
    }
  ): GQL.GetFormAnswersById_form_answer | null => {
    const baseFormAnswer: GQL.GetFormAnswersById_form_answer = {
      __typename: "form_answer",
      id: KNOWN_ANSWER_ID,
      question_id: questionId,
      form_question: {
        __typename: "form_question",
        question_id: questionId,
        category:
          question.form_question?.category ??
          GQL.form_question_category_enum.GeneralQuestion,
        question: {
          __typename: "question",
          type: question.type,
        },
      },
      free_text_answer: null,
      number_answer: null,
      date_answer: null,
      form_answer_options: [],
      document_metadata: [],
    };

    switch (question.type) {
      case GQL.question_type_enum.FreeText:
      case GQL.question_type_enum.Email:
      case GQL.question_type_enum.PhoneNumber:
        if (typeof answer === "string") {
          return {
            ...baseFormAnswer,
            free_text_answer: answer,
          };
        }
        break;

      case GQL.question_type_enum.Date:
        if (typeof answer === "string") {
          return {
            ...baseFormAnswer,
            date_answer: answer,
          };
        }
        break;

      case GQL.question_type_enum.Number:
        if (typeof answer === "number") {
          return {
            ...baseFormAnswer,
            number_answer: answer,
          };
        }
        break;

      case GQL.question_type_enum.MultiSelect:
        if (Array.isArray(answer)) {
          return {
            ...baseFormAnswer,
            form_answer_options: answer.map((optionId) => ({
              __typename: "form_answer_option",
              id: KNOWN_ANSWER_ID,
              form_question_option_id: optionId,
            })),
          };
        }
        break;

      case GQL.question_type_enum.SingleSelect:
        if (typeof answer === "string") {
          return {
            ...baseFormAnswer,
            form_answer_options: [
              {
                __typename: "form_answer_option",
                id: KNOWN_ANSWER_ID,
                form_question_option_id: answer,
              },
            ],
          };
        }
        break;
    }
    return null;
  };

  // Process each answer from the form service data
  Object.entries(formServiceData.data).forEach(([questionId, answer]) => {
    const flatQuestion = formTemplate.sections
      .flatMap((section) => section.questions)
      .find((q) => q.id === questionId);

    const additionalQuestion = formTemplate.sections
      .flatMap((section) =>
        section.questions.flatMap((q) =>
          q.form_question?.form_question_options.flatMap(
            (o) => o.additional_questions
          )
        )
      )
      .find((q) => q?.question.id === questionId);

    switch (flatQuestion?.type ?? additionalQuestion?.question.type) {
      case GQL.question_type_enum.FileUpload:
        // TODO: Implement file upload
        console.warn("Form service does not support file upload yet");
        break;

      case GQL.question_type_enum.Grades:
        if (typeof answer === "string") {
          result.grades_answer.push({
            __typename: "grades_answer",
            id: KNOWN_ANSWER_ID,
            question_id: questionId,
            grade_config: {
              __typename: "grade_config",
              id: answer,
              label: "",
            },
          });
        }
        break;

      case GQL.question_type_enum.Address:
        if (
          typeof answer === "object" &&
          answer !== null &&
          "street_address" in answer
        ) {
          const addressAnswer = answer as {
            street_address: string;
            street_address_line_2: string;
            city: string;
            state: string;
            zip_code: string;
          };
          result.form_address.push({
            __typename: "form_address",
            id: KNOWN_ANSWER_ID,
            question_id: questionId,
            street_address: addressAnswer.street_address,
            street_address_line_2: addressAnswer.street_address_line_2 ?? "",
            city: addressAnswer.city,
            state: addressAnswer.state,
            zip_code: addressAnswer.zip_code,
          });
        }
        break;

      case GQL.question_type_enum.CustomQuestion:
        if (
          typeof answer === "object" &&
          answer !== null &&
          "answersByQuestionId" in answer
        ) {
          const customAnswer = answer;
          Object.entries(customAnswer.answersByQuestionId).forEach(
            ([nestedQuestionId, value]) => {
              let nestedQuestionType: GQL.question_type_enum | undefined;
              for (const relationship of flatQuestion?.custom_question
                ?.custom_question_relationships ?? []) {
                if (relationship.cloned_question.id === nestedQuestionId) {
                  nestedQuestionType = relationship.cloned_question.type;
                  break;
                }
              }
              if (!nestedQuestionType) {
                console.warn(
                  "Nested question type not found",
                  nestedQuestionId
                );
                return;
              }

              const formAnswer = createBaseFormAnswer(nestedQuestionId, {
                type: nestedQuestionType,
              });
              formAnswer.form_question.category =
                flatQuestion?.form_question?.category ??
                GQL.form_question_category_enum.GeneralQuestion;

              const standardAnswer = handleStandardFormAnswer(
                nestedQuestionId,
                value,
                { type: nestedQuestionType }
              );

              if (standardAnswer) {
                result.custom_question_answer.push({
                  __typename: "custom_question_answer",
                  id: KNOWN_ANSWER_ID,
                  question_id: questionId,
                  form_answer: standardAnswer,
                });
              }
            }
          );
        }
        break;

      default:
        const question = flatQuestion ?? additionalQuestion?.question;
        if (!question) {
          console.warn("Question not found", questionId);
          return;
        }
        const standardAnswer = handleStandardFormAnswer(
          questionId,
          answer,
          question
        );
        if (standardAnswer) {
          result.form_answer.push(standardAnswer);
        }
        break;
    }
  });

  return result;
}
